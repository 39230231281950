<template>
  <div class="bet bet-common">
    <div class="bet-overlay"
         v-if="bet.locked">
      <div class="bet-overlay-box">
      </div>
      <i class="bet-overlay-icon material-icons">
        lock
      </i>
    </div>
    <div class="bet-heading clearfix">
      <div class="bet-display-id">{{ displayDate }}</div>
    </div>
    <div class="bet-content">
      <div class="bet-match-name">{{ bet.betEventName }}</div>
      <div class="bet-name">{{ bet.betName }}</div>
      <div class="bet-outcome clearfix">
        <div class="bet-outcome-name">{{ bet.betOutcomeName }}</div>
        <div
          class="bet-outcome-odd"
          v-if="bet.betOutcomeOdd">
          {{ bet.betOutcomeOdd | odd }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, toDate } from 'date-fns';
import { odd } from '@/filters';

export default {
  name: 'PreviewBetCommonCards',
  filters: { odd },
  props: {
    bet: Object,
    betName: String,
    betsLayout: String,
  },
  computed: {
    betStartTimeUTC() {
      const dt = this.bet.betStartTimeUTC;
      return dt ? format(toDate(dt), 'PPPP p') : null;
    },
    displayDate() {
      return this.betStartTimeUTC || this.bet.betStartTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bet-common {
    position: relative;

    .bet-heading {
      position: relative;
      line-height: 1.2;
      height: 22px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .bet-display-id {
      float: left;
      font-weight: 700;
      padding: 0.15em 0.5em 0 0;
    }

    .bet-content {
      padding: 0.2em;
      line-height: 1.2;
    }

    .bet-outcome-name {
      font-weight: 700;
      float: left;
    }

    .bet-outcome-odd {
      font-weight: 700;
      float: right;
    }

    .bet-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }

    .bet-overlay-box {
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.6;
    }

    .bet-overlay-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 2em;
    }
  }
}
</style>
