<template>
  <TicketPreviewBetCommonLayout :bet="bet">
    <div class="wrapper"
         slot-scope="{
            betName,betsLayout
          }">
      <component
        :is="renderBetsComponent"
        :bet="bet"
        :betName="betName"
        :betsLayout="betsLayout">
      </component>
    </div>
  </TicketPreviewBetCommonLayout>
</template>

<script>
import { startCase } from 'lodash';
import config from '../../config';
import TicketPreviewBetCommonCards from './TicketPreviewBetCommonCards';
import TicketPreviewBetCommonList from './TicketPreviewBetCommonList';
import TicketPreviewBetCommonLayout from './TicketPreviewBetCommonLayout';

export default {
  name: 'PreviewBetCommon',
  components: {
    TicketPreviewBetCommonCards,
    TicketPreviewBetCommonList,
    TicketPreviewBetCommonLayout,
  },
  props: {
    bet: {
      type: Object,
    },
  },
  data() {
    return {
      betsLayout: config.betsLayout,
    };
  },
  computed: {
    renderBetsComponent() {
      return `TicketPreviewBetCommon${startCase(this.betsLayout)}`;
    },
  },
};
</script>

<style scoped>
  .wrapper {
    height: 100%;
  }
</style>
