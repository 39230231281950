<template>
  <div class="bet bet-common">
    <div class="bet-overlay"
         v-if="isLocked">
      <div class="bet-overlay-box"
           :class="[color]">
      </div>
      <i class="bet-overlay-icon material-icons">
        lock
      </i>
    </div>
    <div class="bet-content">
      <div class="bet-match-display-id">{{ displayDate }}</div>
      <div class="bet-match-name">{{ bet.betEventName }}</div>
      <div class="bet-name">{{ bet.betName }}</div>
      <div class="bet-outcome-name">{{ bet.betOutcomeName }}</div>
      <div class="bet-outcome-odd">{{ bet.betOutcomeOdd | odd }}</div>
    </div>
  </div>
</template>

<script>
import { format, toDate } from 'date-fns';
import { odd } from '@/filters';

export default {
  name: 'PreviewBetCommonList',
  filters: { odd },
  props: {
    bet: Object,
    betName: String,
    betsLayout: String,
    color: String,
  },
  computed: {
    betStartTimeUTC() {
      const dt = this.bet.betStartTimeUTC;
      return dt ? format(toDate(dt), 'PPPP p') : null;
    },
    displayDate() {
      return this.betStartTimeUTC || this.bet.betStartTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  .bet-common {
    position: relative;
    width: 100%;
    display: flex;

    .bet-display-id {
      font-weight: 700;
      padding-right: 0.5em;
    }

    .bet-content {
      padding: 0.2em;
      line-height: 1.2;
      width: 100%;
      display: flex;

      & > div {
        flex-basis: 23%;
        padding: .2em;
        display: flex;
        align-items: center;

      }

      .bet-match-display-id {
        flex-basis: 5%;
      }

      .bet-outcome-name {
        font-weight: 700;
        flex-basis: 10%;
      }

      .bet-match-name {
        flex-basis: 28%;
      }
    }

    .bet-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }

    .bet-overlay-box {
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.6;
    }

    .bet-overlay-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 2em;
    }
  }
}
</style>
