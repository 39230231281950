<template>
  <div class="wrapper">
    <slot
      :bet="bet"
      :betName="betName">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'PreviewBetCommon',
  props: {
    bet: {
      type: Object,
    },
  },
  computed: {
    betName() {
      let name = this.bet.betName;
      name = this.bet.competitorDisplay ? `${name} - ${this.bet.competitorDisplay}` : name;
      return name;
    },
  },
};
</script>

<style scoped>
  .wrapper {
    height: 100%;
  }
</style>
